.example-enter {
  opacity: 0.01;
  z-index: -3000;
  max-height: 0;
  display: block;
  overflow: hidden;
}

 
.example-enter.example-enter-active {
  transition: .35s ease;
  max-height: 2000px;
  z-index: -3000;
  opacity: 1;
}

.example-exit {

  z-index: -3000;
  opacity: 1;
}

.example-exit.example-exit-active {
  transition: .35s ease;
  z-index: -3000;
  opacity: 0.01;
  display: block;
}