// Override default variables before the import
/*$body-bg: #000;*/
// Import Bootstrap and its default variables

@import '~bootstrap/scss/bootstrap.scss';

* {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.disabled-link {
  pointer-events: none;
}

.tooltip-inner {
  white-space: pre-wrap;
  max-width: 300px;
}
